const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6957991",
    space_js: "//xsfpb.moonreader.cn/production/ytsbq/common/ub_b/production/c.js"
  }
];

const Banner = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6957993",
    space_js: "//xsfpb.moonreader.cn/production/b/production/wve/openjs/t/xeeo.js",
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6957992",
    space_js: "//xsfpb.moonreader.cn/site/avu/d/s_wdd/openjs/k.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6957994",
    space_js: "//xsfpb.moonreader.cn/site/c/openjs/xwf_u_y_fft.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6957995",
    space_js: "//xsfpb.moonreader.cn/site/d/static/y/openjs/xgvz/ggv.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native1,
  Home_banner: Banner,
  Detail_inter: Inter,
  Detail_native: Native1,
  Detail_banner: Banner,
  List_native: Native1,
  List_banner: Banner,
  Result_inter: Inter,
  Result_banner: Banner,
  Result_native: Native1,
  Result_native_center1: Native2,
  Result_native_center2: Native3,
};
